<template>
    <section class="assetsClassification">
        <div class="top-bar bg-white" style="justify-content: left">
            <div style="margin-right: 10px;">
                <span style="padding: 0 10px">分类编码:</span>
                <el-input size="small" style="width: 200px;" v-model="formSearch.contractNum" placeholder="请输入资产名称"/>
            </div>
            <div style="margin-right: 10px;">
                <span style="padding: 0 10px">分类名称:</span>
                <el-input size="small" style="width: 200px;" v-model="formSearch.contractNum" placeholder="请输入资产名称"/>
            </div>
            <el-button size="small" type="primary" @click="handleSearch">查询</el-button>
            <el-button size="small" @click="handleReset">重置</el-button>
            <el-button size="small" type="primary" @click="openAdd" round>新建分类</el-button>
        </div>
        <div style="padding: 25px;background-color: #ffffff;border-radius: 10px">
            <r-e-table class="bg-white" ref="dictionaryTableRef" rowKeyId="code" :data="tableData" :default-sort="{prop: 'code', order: 'ascending'}"
                       :columns="classificationTableColumn" :query="formSearch" :show-pagination="false" :height="840">
                <template slot="empty">
                    <el-empty/>
                </template>
            </r-e-table>
        </div>

        <dialog-add-classification ref="dialogAddClassification"/>
    </section>
</template>

<script>
    import {classificationTableColumn} from "@/views/property-management/assets-management/data";

    export default {
        name: "assetsClassification",
        data() {
            return {
                classificationTableColumn,
                formSearch: {},
                tableData: []
            };
        },
        components: {
            dialogAddClassification: () => import("./components/dialog-add-classification")
        },
        methods: {
            handleSearch() {

            },
            handleReset() {

            },
            handleContractExport() {

            },
            openAdd() {
                this.$refs["dialogAddClassification"].openDialog();
            }
        },
        async mounted() {
            this.tableData = await this.$store.dispatch('app/getDictionaryByCodeActions', 200000);
        },
        watch: {}
    }
</script>

<style lang="scss" scoped>
    .assetsClassification {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(10px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .el-radio-button.is-active {
                box-shadow: 0 0 10px #DDD inset;
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }

        .title {
            height: VH(50px);
            line-height: VH(50px);
            color: #666;
            padding-left: VW(10px);
            position: relative;

            &::before {
                width: 5px;
                height: 40%;
                background-color: #5C84FB;
                content: '';
                position: absolute;
                left: 0;
                top: 30%;
            }
        }
    }
</style>